import { FetchData } from './FetchData'
import { url } from '../config';


const getHeaders = () => {
    return {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenPISblog')   

    }    
}

export const getById = async(idCrypted) => {
    const response = await FetchData(url.blog.getById + idCrypted, 'GET', getHeaders())
    return response.datos;
}

export const getNotas = async() => {

    const response = await fetch(url.blog.getNotas, {
        method: 'GET',
        headers: getHeaders()
    });

    if(response.ok){ 
        const data = await response.json();
        if(data){
            return data;
        }
    }else{
        return {
            error: true,
            message: "Error al obtener datos"
        };
    }

}

export const getByUsu = async() => {

    const response = await FetchData(url.blog.byUserPIS, 'GET',  getHeaders())
    return response.datos;

}

export const getAll = async() => {
    const response = await FetchData(url.blog.getAll, 'GET',  getHeaders())
    return response.datos;
}

export const getByIdModulo = async(modulo) => {   
    //El id viene encriptado
    const response = await FetchData(url.blog.getByIdModulo + modulo, 'GET',  getHeaders())
    return response.datos;

}

export const isAuthorized = async() => {
    const response = await FetchData(url.blog.isAuthorized, 'GET',  getHeaders())
    return response.datos;
}

export const isAdmin = async() => {

    const response = await FetchData(url.blog.isAdmin, 'GET',  getHeaders())
    return response.datos;

}