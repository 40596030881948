// const setToken = (token = null) => {
//   localStorage.setItem('tokenPISblog', token);    
// }

export const getToken = () => {
    const storage = localStorage.getItem('tokenPISblog');
    if(storage === undefined || storage === null || storage === 'null' || storage === '' || storage === 'undefined'){
        return false;
    }else{
        return storage;
    }   
}