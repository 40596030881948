import React, { useEffect } from 'react'
import { Card, Tag, Typography } from 'antd';
// import estilosBlog from '../assets/css/estilosBlog.css';
import { CopyLink } from './CopyLink';
import { useNavigate } from 'react-router-dom';
import { rutas } from '../config';

const { Title } = Typography;

export const CardBlog = ({data = {}}) => {
    
    const navigate = useNavigate();

    const isPreview = new URLSearchParams(window.location.search).get("preview") || false;
    const isApp = new URLSearchParams(window.location.search).get("app") || false;
    useEffect(() => {
            if(isPreview){
                if(isApp){
                    navigate(rutas.blog.nota+isPreview+"&app=true");
                }else{
                    navigate(rutas.blog.nota+isPreview);
                }
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const parseFecha = (fecha = null) => {
        if(!fecha) fecha = new Date();
        
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const fechaParseada = new Date(fecha).toLocaleDateString('es-ES', options);
        return fechaParseada;
    }

    const handleClick = () => {
        navigate(rutas.blog.nota+data.id);
    }

   return (
        <Card style={{ width: "100%", padding: 0 }}>
            {/* Encabezado */}
            <div style={{ marginTop: 20, marginLeft: 20 }} >
                <div>
                    <Tag color={data.background}>{data.nombreModulo || ""}</Tag>
                    <Tag color="rgb(0, 159, 151)">{data.nombreTipoNota || ""}</Tag>
                </div>
                <hr class="hr-border"></hr>
                <div onClick={handleClick}>
                    <Title level={5} style={{
                        fontWeight: "bold",
                        cursor: "pointer",
                    }} >{data.titulo || ""} </Title>
                </div>
            </div>
            {/* Descripcion */}
            <div style={{margin: 20,
                textAlign: "justify",
                textJustify: "inter-word",
                wordBreak: "break-word",
            }}>
                <p
                    style={{
                        color: "gray",
                        fontSize: 16,
                    }}
                >
                    {data.descripcion || ""}
                </p>
            </div>
            {/* Footer*/}
            <hr style={{width:"200%", margin:0}}/>
            <div style={{
                display: "flex",
            }}>

                <div>
                    <div style={{ color:"gray",  marginTop: 15}}>{parseFecha(data.fecha) || 
                    //fecha actual
                    parseFecha()
                    }</div>
                </div>
                <div style={{marginLeft: "auto", marginTop: 15}}>
                    <CopyLink color="gray"hashId={data.id || null} />
                </div>    
            </div>
        </Card>
    )
}
