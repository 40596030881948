import React, {useEffect, useState, useRef} from 'react'
import {  useParams } from 'react-router-dom'
import { ConstructorForm } from '../components/ConstructorForm.js'
import { CardComponent as Card } from '../components/Card'
import { Col, Row,  Alert } from 'antd'
import { add, getById, update } from '../utils/Notas.js'
import { VistaPrevia } from '../components/VistaPrevia.js'
import {Navbar} from '../components/Navbar'
import { useNavigate } from 'react-router-dom'
import { url } from '../config.js'

export const Formulario = () => {
    const { idNota } = useParams();
    const formRefConfig = useRef();
    const formRefNota = useRef();
    const txtBtn = idNota ? "Actualizar" : "Crear";
    // const [dataNota, setDataNota] = useState({});  // Para mostrar un spinner mientras se carga la información
    const [formNotaState, setFormNota] = useState({});
    const [formConfigState, setFormConfig] = useState({});
    const [showForm, setShowForm] = useState(false);    
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);  
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [alertDescription, setAlertDescription] = useState("");
    // const [isAdmin, setIsAdmin] = useState(false);

    // useEffect(() => {   
    //     handleIsAdmin();
    // },[])

    useEffect(() => {
        if(idNota){
            getNota(idNota);
        }else{
            setShowForm(true);
            setFormNota(formNota);
            setFormConfig(formConfiguracion);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[idNota])

    useEffect(() => {
        if(showAlert){
            setTimeout(() => {
                setShowAlert(false);
            }, 3000);
        }
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[showAlert])

    // const handleIsAdmin = async () => {

    //     if(isValidToken() && getToken() !== false){
    //         const response = await fetchAdmin();

    //         if(response.permisos){
    //             setIsAdmin(true);
    //         }

    //     }

    // }

    const succesAlert = (message, description) => {
        setAlertMessage(message);
        setAlertDescription(description);
        setAlertType("success");
        setShowAlert(true);
    }

    const getDataForm = (data) => {
        console.log(formRefNota.current.getFieldsValue());
        const totalData = {...data, ...formRefConfig.current.getFieldsValue()}
        totalData.estatus ? totalData.estatus = 1 : totalData.estatus = 0;
        totalData.tipoPublico ? totalData.tipoPublico = 1 : totalData.tipoPublico = 0;
        totalData.descripcion = totalData.contenido.substring(0, 100); 
        totalData.idModulo = parseInt(totalData.idModulo);
        totalData.idTipoNota = parseInt(totalData.idTipoNota);
        return totalData;
    }

    const addNota = async(data) => {
        const totalData = getDataForm(data);
        const dataToSend = JSON.stringify(totalData);
        const response = await add(dataToSend);
        console.log(totalData);

        if(!response.error){
            navigate(url.notas.viewAll);
        }

    }

    const updateNota = async(data) => {
        console.log(data);
        const totalData = getDataForm(data);
        const dataToSend = JSON.stringify(totalData);
        const response = await update(idNota, dataToSend);
        console.log(response);
        if(!response.error){
            succesAlert("Nota actualizada", "La nota se ha actualizado correctamente");
        }
    }


    const formNota = {
            title: "Test",
            layout: "vertical",
            id: "formNota",   
            ref: formRefNota,
            style: {
                paddingInline: 10,
                margin: 0
            },
            onFinish: addNota,
        elements:[
            {
                type: "text",
                row: 2,
                col: 0,
                propsForm: {
                    label: "Título de la nota",
                    name: "nombre",
                    rules: [
                        {
                            required: true,
                            message: "Por favor rellenar campo",
                        },
                    ],
                },
                propsElement: {
                    placeholder: "Ej. Bienvenido a PISblog",
                    // disabled: true,
                },
            },
            {
                type: "modulos",
                row: 3,
                col: 0,
                propsForm: {
                    label: "Modulos",
                    name: "idModulo",
                    rules: [
                        {
                            required: true,
                            message: "Por favor rellenar campo",
                        },
                    ],
                },
                propsElement: {
                    placeholder: "Selecciona un modulo",
                    // disabled: true,
                },
            },
            {
                type: "tiposNota",
                row: 4,
                col: 0,
                propsForm: {
                    label: "Tipos de nota",
                    name: "idTipoNota",
                    rules: [
                        {
                            required: true,
                            message: "Por favor rellenar campo",
                        },
                    ],
                },
                propsElement: {
                    placeholder: "Selecciona un tipo de nota",
                    // disabled: true,
                },
            },
            {
                // type: "textArea",
                type: "quill",
                row: 5,
                col: 0,
                propsForm: {
                    label: "Contenido",
                    style: {
                        marginTop: 10,
                        width: "135%",
                    },

                    name: "contenido",
                    // rules: [
                    //     {
                    //         required: true,
                    //         message: "Por favor rellenar campo",
                    //     },
                    // ],
                },
                propsElement: {
                    id: "contenido",
                    // disabled: true,
                },
            }
           
        ],
        buttons: [
            {
                type: "submit",
                text: txtBtn,
                style: {
                    width: "135%",
                },
                propsElement: {
                    className: "btnSubmit",
                    type: "primary",
                    style: {
                        width: "100%",
                    },
                },
            },
    
        ]

    }
    const formConfiguracion = {
            title: "Test2",
            layout: "horizontal",
            ref: formRefConfig,
            style: {
                background: "#f0f0f0",
                width: "100%",
                //borde 
                borderRadius: "10px",
                border: "1px solid gray",
                boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)"          
            },
        elements:[
            {
                type: "hr",
                row: 1,
                col: 0,
                propsForm: {
                    style:{
                        // background: "gray",
                        padding: 6
                    }
                },
                propsElement: {
                    titleBeforeLine: "Configuración",
                    size: 4,
                    style: {
                        margin: 0,
                        marginBottom: 10,
                        textAlign: "center",
                        // background: "gray",
                        // color: "white",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        height: 8,
                    },
                },


            },
            {
                type: "switch",
                row: 2,
                col: 0,
                propsForm: {
                    label: "Estatus",
       
                    style:{
                        marginLeft: 8
                    },
                    name: "estatus",
                   
                },
                propsElement: {
                    style: {
                        
                        width: "80%",
                    },
                    unCheckedChildren: "Inactivo",  
                    checkedChildren: "Activo",
                    defaultChecked: true,
                    // disabled: true,
                },

            },
            {
                type: "switch",
                row: 3,
                col: 0,
                propsForm: {
                    label: "Publico",
                    style:{
                        marginLeft: 8
                    },
                    name: "tipoPublico",
            
                },
                propsElement: {
                    style: {
                        
                        width: "80%",
                    },
                    unCheckedChildren: "Privado",  
                    checkedChildren: "Publico",
                    // disabled: true,
                },
            },
            {
                type: "hr",
                row: 4,
                col: 0,
                propsForm: {
                    style: {
                        margin: 0,
                        height: 5,
                        padding: 8
                    },
                },
                propsElement: {
                    titleBeforeLine: "Jvalenzuela1@ucol.mx",
                    size: 5,
                    style: {
                        textAlign: "center",
                        margin: 0,
                    },
                },
            },
        ],

        buttons: [
            {
                type: "submit",
                text: "Enviar",
                style: {
                    width: "135%",
                },
                propsElement: {
                    className: "btnSubmit",
                    type: "primary",
                    style: {
                        display: "none",
                        width: "50%",
                    },
                },
            }
        ]
    }

    
    const getNota = async(id = null) => {

        if(id !== null){
            const response = await getById(id);
            if(response.error){
               console.log(response);            }  

            // const dataObj = {
            //     nombre: response.nombre,
            //     idModulo: response.idModulo,
            //     idTipoNota: response.idTipoNota,
            //     contenido: response.contenido,
            // }
            //desparsear el contenido
            response.contenido = decodeURIComponent(response.contenido);
            console.log(response.contenido);
            const copyNota = {...formNota};
            const copyConfig = {...formConfiguracion};
            copyNota.initialValues = response; 
            copyNota.onFinish = updateNota;
            copyConfig.initialValues = response;
            setFormNota(copyNota);
            setFormConfig(copyConfig);
            setShowForm(true);
           
        }
    }

  return (
    <div>
            <Card titulo={txtBtn + " nota"} size={80} contenido={
                    <>
                    <Navbar />
                <Row style={{marginTop: 10}}>
                
                        <Col xs={24} sm={20} md={16} lg={18} xl={18} xxl={18}> 
                            <br/>
                            <br/>
                            <br/>
                            { showForm &&

                            <>
                                { showAlert &&
                                    <Alert
                                        message={alertMessage}
                                        description={alertDescription}
                                        type={alertType}
                                        showIcon
                                        onClose={() => setShowAlert(false)}
                                    />
                                }
                                <ConstructorForm  jsonForm={formNotaState} />
                                </>
                            }
                        </Col>
                        <Col style={{height: "80%"}} xs={24} sm={4} md={8} lg={6} xl={6} xxl={6}>
                            <Row>
                                <Col span={24}>
                                    <VistaPrevia />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                { showForm &&
                                    <ConstructorForm jsonForm={formConfigState} />
                                }
                                </Col>
                            </Row>
                        </Col>
                    
                    </Row>
                    </>
                } 
            />
    </div>
  )
}
