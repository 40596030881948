import React from 'react'
import { Row, Card, Col, Typography } from 'antd';

export const CardComponent = ({contenido, titulo, size = 80, position = "center"}) => {


  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: position, minHeight: '10vh', marginTop: 10, marginBottom: 10 }}>
    <Row style={{ width: size+'%' }}>
      <Col md={{ span: 24 }}>
        <Card bordered={false} style={{ minWidth: 550, maxWidth: 1300, boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
          <Typography.Title level={3}>{titulo}</Typography.Title>
            {contenido}
        </Card>
      </Col>
    </Row>
  </div>
  )
}
