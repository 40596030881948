import React, {useEffect, useState, useRef} from 'react'
import { Button, Input, Table as AntdTable, Tooltip,  Spin, Alert } from 'antd';
import { SearchOutlined, CloseOutlined, ReloadOutlined, CloudUploadOutlined, CloudDownloadOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { FaRegFilePdf } from "react-icons/fa";
import { PiMicrosoftExcelLogoLight } from "react-icons/pi";
import { IoIosSwitch } from "react-icons/io";

const { Search } = Input;
export const resetTable = () => document.getElementById('btnReloadTable').click();
export const Table = ({
    buttonsTable={
        reload:{
            show: true,
            icon: React.createElement(ReloadOutlined, null)  ,
            text: "Recargar",
            key: "reload",
            id: 'btnReloadTable'

        },
        pdf: {
            show: false,
            icon: React.createElement(FaRegFilePdf, null),
            text: "PDF",
            onClick: () => {}
        }, 
        excel: {
            show: false,
            icon: React.createElement(PiMicrosoftExcelLogoLight, null),
            text: "Excel",
            onClick: () => {}
        },
        uploadFile: {
            show: false,
            icon: React.createElement(CloudUploadOutlined, null)  ,
            text: "Upload",
            onClick: () => {}
        },
        downloadFile:  {
            show: false,
            icon: React.createElement(CloudDownloadOutlined, null)  ,
            text: "Download",
            onClick: () => {}
        },
        deleteFile:  {
            show: false,
            icon: React.createElement(CloseOutlined, null, "PDF")  ,
            text: "PDF",
            onClick: () => {}
        },
        toogle: {
            show: false,
            icon: React.createElement(IoIosSwitch, null),
            text: "Toogle",
            onClick: () => {}
        }

    },
    columns=[], rowKey = "", /*dataArray = [],*/dataUrl="", urlPost="", urlPut="", urlDelete=""
    }) => {
        const [data, setData] = useState([]);
        const [isLoading, setLoading] = useState(false);
        const [itemsBtn, setItemsBtn] = useState([]);
        const [columnsSource, setColumnsSource] = useState([]);
        const [searchedColumn, setSearchedColumn] = useState('');
        const [searchText, setSearchText] = useState('');
        const [alertMessage, setAlertMessage] = useState("");
        const [alertDescription, setAlertDescription] = useState("");
        const [alertType, setAlertType] = useState("info"); 
        const searchInput = useRef(null);

        useEffect(() => {
            getButtonsTable();
            buildTable(dataUrl, columns);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {

            //despues de 10 segundos se cierra el mensaje
            setTimeout(() => {
                setAlertMessage("");
                setAlertDescription("");
                setAlertType("info");
            }, 10000);

        }, [alertMessage]);

        // useEffect(() => {
        //     addData(dataArray);
        // }, [dataArray]);

        const reloadTable = () => buildTable(dataUrl, columns);
        

        const alertSuccess = (message, description) => {
            setAlertMessage(message);
            setAlertDescription(description);
            setAlertType("success");
        }

        const alertError = (message, description) => {
            setAlertMessage(message);
            setAlertDescription(description);
            setAlertType("error");
        }

        const alertWarning = (message, description) => {    
            setAlertMessage(message);
            setAlertDescription(description);
            setAlertType("warning");
        }

        const getData = async (url = "") => {
            try{
                setLoading(true);
                if(url === "") throw new Error("URL no definida");

                const response = await fetch(url,{
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": "Bearer "+localStorage.getItem('tokenPISblog')
                    }
                });
                setLoading(false);
                
                const data = await response.json() || null;
                
                if(data.error || response.status !== 200 || data === null) throw new Error();

                if(data.datos.length <= 0){
                    alertWarning("Warning", "No hay datos para mostrar");
                } 

                alertSuccess("Success", "Datos obtenidos correctamente");
                return data.datos;
            }catch(error){
                setLoading(false);
                alertError("Error", error.message);
                return;
            }
        }

        const checkTypeColumns = (columnsParam) => {
            columnsParam.forEach((column)=>{
                if(!column.type)
                    column.type = 'number';
            });
            return columnsParam;
        }

        const getButtonsTable = () => { 
            const items = Object.entries(buttonsTable).map(([key, value]) => {
                if(!value.show) return null;

                const tipos = {
                    reload: reloadTable,
                    pdf: value.onClick,
                    excel: value.onClick,
                    uploadFile: value.onClick,
                    downloadFile: value.onClick,
                    deleteFile: value.onClick,
                    toogle: value.onClick
                }

                return (
                    <Tooltip  title={value.text}>
                        <Button style={{margin: 5,}} key={key} id={value.id ? value.id : ''} icon={value.icon} onClick={() => tipos["reload"]()}/>
                    </Tooltip>
                )
            });
            //remove null
            items.filter((item) => item !== null);

            setItemsBtn(items);
        }

        const handleSearch = (selectedKeys, confirm, dataIndex) => {
            confirm();
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
          };
        const handleCloseSearch = (close) => { 
            
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 100);
            close();
        }

        const filterSearchProps = (column) => {
            const props = {
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                    <div
                      style={{
                        padding: 8,
                      }}
                      onKeyDown={(e) => e.stopPropagation()}
                    >
 
                      <Search 
                        placeholder={`Buscar ${column.title}`} 
                        onSearch={() => handleSearch(selectedKeys, confirm, column.dataIndex)} 
                        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        enterButton={<SearchOutlined/>}
                        style={{
                            marginBottom: 8,
                            display: 'block',
                          }}
                        />
                        <Button
                          type="default"
                          size="small"
                          onClick={() => handleCloseSearch(close)}
                        >
                          Cancelar
                        </Button>
                    </div>
                  ),
                  filterIcon: (filtered) => (
                    <SearchOutlined
                      style={{
                        color: filtered ? '#1677ff' : undefined,
                      }}
                    />
                  ),
                  onFilter: (value, record) => { 
                    return record[column.dataIndex].toString().toLowerCase().includes(value.toLowerCase());
                },
                  onFilterDropdownOpenChange: (visible) => {
                    if (visible) {
                      setTimeout(() => searchInput.current?.select(), 100);
                    }
                  },
                  render:  searchedColumn === column.dataIndex ? (text) =>
                    (
                      <Highlighter
                        highlightStyle={{
                          backgroundColor: '#ffc069',
                          padding: 0,
                        }}
                        searchWords={[searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : ''}
                      />
                    ) : column.render,
            }
            return props;
        }

        const filterSorterProps = (column) => {

            const types = {
                folio: (a, b) => a[rowKey] - b[rowKey],
                number: (a, b) => a[column.dataIndex] - b[column.dataIndex],
                text: (a, b) => a[column.dataIndex].localeCompare(b[column.dataIndex]),
                date: (a, b) => new Date(a[column.dataIndex]) - new Date(b[column.dataIndex]),
                boolean: (a, b) => a[column.dataIndex] - b[column.dataIndex]
            }

            return types[column.type];
        }

        const getFilters = (column) => {
    
            let filters = {};
            if(column.type !== 'action'){

                if(column.type !== 'number' && column.type !== 'boolean')
                    filters = filterSearchProps(column);
                
                filters.sorter = filterSorterProps(column);
                filters.sortDirections = ['descend', 'ascend'];
            
            }
            return filters;
        }
        
        const getButtonsAction = (column) => {
            if (column.type === 'action' && column.typesAction.length > 0) {
              const renderFunctions = column.typesAction.map(element => {
                if (element.type === 'edit') {
                  return (_, record) => (
                    <Tooltip title={element.text || "Editar"}>
                      <Button onClick={() => element.onclick(element.allData ? record : record[rowKey])} icon={<EditOutlined />} type="primary" style={{ margin: 2 }} />
                    </Tooltip>
                  );
                }
                if (element.type === 'delete') {
                  return (_, record) => (
                    <Tooltip title={element.text || "Eliminar"}>
                      <Button onClick={() => element.onclick(element.allData ? record : record[rowKey])} icon={<DeleteOutlined/>} type='primary' style={{ margin: 2 }}  danger/>
                    </Tooltip>
                  );
                }
                return null; // Si hay un tipo desconocido, devolvemos null
              });
              // Combina todas las funciones de renderizado en una sola
              column.render = (_, record) => renderFunctions.map(fn => fn(_, record));
            }
            return column;
          }
          

        const buildTable = async(url, columnsParam) => {
            const columnsTable = checkTypeColumns(columnsParam);
            const columnsSource = columnsTable.map((column) => { 

                if(column.type === 'action'){
                    column = getButtonsAction(column);
                }
                const filters = getFilters(column);
                
                column = {...column, ...filters};
                return column;
            });
            setColumnsSource(columnsSource);
            const dataOfUrl = await getData(url) || [];
            setData(dataOfUrl);
        }


        const handleCloseAlert = () => {
            setAlertMessage("");
            setAlertDescription("");
            setAlertType("info");
        }

        // const addData = (nData) => setData(prevData => [...prevData, ...nData]);

  return (
    <>
        {isLoading ?
         <Spin><AntdTable style={{width: "100%"}} columns={columnsSource} dataSource={data} rowKey={rowKey} /></Spin> 
        :
        <div  className='scrollable-div'>
                    <div style={{
                        width: '50%',
                    }}>   
                        {itemsBtn}
                    </div>
                        { alertMessage !== "" &&
                        <Alert 
                            onClick={handleCloseAlert}
                            style={{ margin: 5 }}
                            message={alertMessage}
                            description={alertDescription}
                            showIcon
                            type={alertType}
                            onClose={handleCloseAlert} // Agrega esta línea para manejar el cierre del Alert
                        />
                        }
                    <AntdTable style={{width: "100%"}} columns={columnsSource} dataSource={data} rowKey={rowKey} />
        </div>
        }
    </>
  )
}
