
import { FetchData } from './FetchData'
import { url } from '../config'

const getHeaders = () => {
    return {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('tokenPISblog')
    }
}

export const dataById = async(id) => {

    const response = await FetchData(url.usuarios.getById + id, 'GET', getHeaders())
    return response.datos;

}   

export const updateUser = async(data, id) => { 
    try{
        const response = await fetch(url.usuarios.update + id, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify(data)
        })
        
        if(response.status === 200){
            
            const data = await response.json();
            
            if(data){

                if(!data.error){

                    return {
                        error: false,
                        message: "Usuario actualizado correctamente",
                        data: data
                    }
                }else{
                    return {
                        error: true,
                        message: data.message
                    }
                }
            }
            return {
                error: true,
                message: "Error al actualizar usuario"
            }
        }
        return {
            error: true,
            message: "Error al actualizar usuario"
        }

    }catch(e){
        return {
            error: true,
            message: "Error al actualizar usuario"
        }
    }
    
}