import React, { useEffect, useState} from 'react'
import { NotaBlog } from '../components/NotaBlog'
import { HeaderBlog } from '../components/HeaderBlog'

import './../assets/css/notaBlog.css'


export const ViewNota = () => {
    const [scrollFromFrame, setScrollFromFrame] = useState(0);
    let updatePositionScroll = null; // Variable para almacenar el listener de mensajes

    useEffect(() => {
        updateSetupIframeScroll();

        // Limpiar el listener cuando el componente se desmonte
        return () => {
            if (updatePositionScroll) {
                window.removeEventListener('message', updatePositionScroll);
            }
        };
        // eslint-disable-next-line
    }, []);

    function updateSetupIframeScroll() {
        // Eliminar el listener anterior si existe
        if (updatePositionScroll) {
            window.removeEventListener('message', updatePositionScroll);
        }

        // Configurar un nuevo listener de mensajes
        updatePositionScroll = (event) => {
            if (event.data && event.data.task === 'setWindowScroll') {
                const scrollY = event.data.scrollY;
                // Actualizar el scroll de la ventana
                setScrollFromFrame(scrollY);
                window.scrollTo(0, scrollY); // Desplazar la ventana principal
            }
            console.log("test",event)
        };

        // Agregar el nuevo listener de mensajes
        window.addEventListener('message', updatePositionScroll);
    }

    return (
        <>
            <div style={{
                overflowX: 'hidden',
                top: scrollFromFrame + 24 + 'px',
                position: 'sticky',
            }}>
                <div style={{
                    height: 300,
                    marginBottom: 20,
                }}>
                    <HeaderBlog />
                </div>
                <div style={{
                    padding: 10
                }}>
                    <NotaBlog />
                </div>
            </div>
        </>
    );
};