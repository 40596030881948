import React,{useEffect, useRef, useState} from 'react'
import Quill from 'quill';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import '../assets/css/editor.css';

const customToolbar = [
  [{ header: '1' }, { header: '2' }, { font: [] }],
  [{ size: [] }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
  ['link', 'image', 'video'],
  ['clean'],
  ['code-block'],
  [{ align: [] }],
  [{ color: [] }, { background: [] }], 
];

export const Editor = ({form, id, style, ref, defaultValue, theme, ...rest}) => {

    const editorRef = useRef(null);
    const [htmlContent, setHtmlContent] = useState('');

    useEffect(() => {
      const initializeEditor = () => {
        const editorElement = document.getElementById(id);
        if (!editorElement) return;
  
        const quillInstance = new Quill(editorElement, {
          theme,
          placeholder: 'Escribe algo...',
          modules: {
            toolbar: {
              container: customToolbar,
            },
          },
        });
  
        editorRef.current = quillInstance;
  
        // Set initial content if available
        if (form && form.getFieldValue(id) !== undefined) {
          try {
            let data = form.getFieldValue(id);
            data = data.replace(/<p>/g, "").replace(/<\/p>/g, "");
            quillInstance.setContents(JSON.parse(data));
          } catch (e) {
            quillInstance.setContents([]);
          }
        }
  
        quillInstance.on('text-change', () => {
          const contents = quillInstance.getContents();
          if (form) {
            form.setFieldsValue({
              [id]: JSON.stringify(contents),
            });
          }
        });
      };

      initializeEditor();

    }, [id, form, theme]);

    const showHtml = () => {
      const html = editorRef.current.root.innerHTML;
      setHtmlContent(html); // Set the HTML content to state
    };


    return (

      <>
      <Button onClick={showHtml}>Mostrar HTML</Button>
       <ReactQuill id={id} style={style} ref={ref} defaultValue={defaultValue} {...rest} /> 
         <TextArea value={htmlContent} rows={10} style={{ marginTop: '20px' }} />
        </>
    )
}
