import React from 'react'
import { NotaBlog } from '../components/NotaBlog'
import { HeaderBlog } from '../components/HeaderBlog'
export const Preview = () => {
  return (
    <div>
        <div style={{
            height: 300
        
        }}>
        <HeaderBlog />
        </div>
        <div style={{

            padding: 80,
        }}>
            <NotaBlog isPreview={true} />

        </div>
    </div>
)
}
