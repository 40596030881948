import React, { useState} from 'react'
import { Navbar } from '../components/Navbar.js'
import { CardComponent as Card}  from '../components/Card.js'
import { ConstructorForm } from '../components/ConstructorForm.js'
import { Table, resetTable } from '../components/Table.js'
import { getById as getModulo, add as addNota, update as updateNota } from '../utils/TiposNotas.js'
import { Modal, Button, Spin, Tooltip, ColorPicker } from 'antd'
import strings  from '../values/strings'
import { DiffOutlined } from '@ant-design/icons'
import { url} from '../config.js'



export const TiposNotas = () => {

const [openModal, setOpenModal] = useState(false);
const [showForm, setShowForm] = useState(false);
const [form, setForm] = useState({});
const [ isLoading, setIsLoading ] = useState(false);
const [editIndex, setEditIndex] = useState(0);
// const [ isAdmin, setIsAdmin ] = useState(false);

const textTable = strings.tables.columns.title.tiposNotas;
const keyTable = strings.tables.columns.keys;
const valuesTable = strings.tables.columns.values.tiposNotas;

const formJson = {

    title: "Test",
    layout: "horizontal",
    id: "formNota",  
    initialValues: {
        nombre: "",
        estatus: true,
    },
    style: {
        width: '100%',
    },
    elements: [

        {
            type: "text",
            row: 1,
            col: 0,
            propsForm: {
                label: "Nombre tipo nota",
                name: "nombre",
                rules: [
                    {
                        required: true,
                        message: "Por favor rellenar campo",
                    },
                ],
            },
            propsElement: {
                placeholder: "Ej. Bienvenido a PISblog",
                // disabled: true,
            },
        },
        {
            type: "switch",
            row: 2,
            col: 0,
            propsForm: {
                label: "Estatus",
                name: "estatus",
                valuePropName: "checked",
            },
            propsElement: {
                checkedChildren: "Activo",
                unCheckedChildren: "Inactivo",
            },
        },
        {
            type: "colorPicker",
            row: 3,
            col: 0,
            propsForm: {
                label: "Color",
                name: "background",
            },
            propsElement: {
            },   
        },
        {
            type: "hidden",
            row: 4,
            col: 0,
            propsForm: {
                label: "",
                name: "id",
            },
            propsElement: {
            },   
        }
    ],
    buttons: [
        {
            type: "submit",
            text: "Guardar",
            propsElement: {
                className: "btnSubmit",
                type: "primary",
                style: {
                  
                },

            },
        },

    ]


}

const add = async (data) => {

    setIsLoading(true);
    setOpenModal(false);
    data.estatus = data.estatus ? 1 : 0;
    data = JSON.stringify(data);
    const res = await addNota(data);
    if(res.error){
        setOpenModal(true);
    }

    setIsLoading(false);
    resetTable();
    handleCloseModal();

}

const update = async (data) => {

    setIsLoading(true);
    const id = data.id;
    setOpenModal(false);
    data.estatus = data.estatus ? 1 : 0;
    data = JSON.stringify(data);
    const res = await updateNota(data, id);

    if(res.error){
        setOpenModal(true);
    }   

    setIsLoading(false);
    handleCloseModal();
    resetTable();
}

const handleOpenModal = async(id) => {
    setOpenModal(true);
    const formPlantilla = {...formJson};
    if(id === 0){
        
        formPlantilla.onFinish = add;
        setForm(formPlantilla);

    }else{
        setEditIndex(id);
        const dataTipo = await getModulo(id);
        dataTipo.estatus = parseInt(dataTipo.estatus) === 1 ? true : false;
        formPlantilla.onFinish = update;
        formPlantilla.initialValues = {...dataTipo, id: id};
        setForm(formPlantilla);
    }
    setShowForm(true);
}

const handleCloseModal = () => {
    setOpenModal(false);
    setShowForm(false);
}

    const columns = [
        {
            title: textTable.title,
            dataIndex: 'nombre',
            key: keyTable.name,
            type: 'text',
            width: 80,
            fixed: 'left',
        },
        {
            title: textTable.status,
            dataIndex: 'estatus',
            key: keyTable.status,
            type: 'boolean',
            width: 80,
            fixed: 'left',
            render: (_, record) => {
                return parseInt(record.estatus) === 1 ?
                <Tooltip title={valuesTable.status.active}>
                  <ColorPicker defaultValue={"green"} />
                </Tooltip> 
                 :
                <Tooltip title={valuesTable.status.inactive}>
                  <ColorPicker defaultValue={"red"} />
                </Tooltip>
              }
        },
        {
            title: textTable.actions,
            dataIndex: 'id',
            key: keyTable.actions,
            type: 'action',
            typesAction: [
              {
                type: 'edit',
                text: textTable.edit,
                onclick: (id) => {handleOpenModal(id) }
              },
            ], 
            width: 80,
            fixed: 'left'
        },
    ];

return (
    <>
    <Spin fullscreen spinning={isLoading}/>
        <Card contenido={
            <>
            <Modal
                    destroyOnClose
                    title={editIndex === 0 ? "Agregar tipo de nota" : "Editar tipo de nota"}
                    open={openModal}
                    onCancel={handleCloseModal}
                    footer={null}
                >
                        <>
                        <hr/>
                        {showForm ? <ConstructorForm jsonForm={form}  /> : <Spin size="large" />} 
                        </>
                </Modal>
                <Navbar />
                <Button type="primary" icon={<DiffOutlined  style={{fontSize: 18}}/>} style={{float:"right", margin: 15}} onClick={() => handleOpenModal(0)}>Agregar</Button> 
            <Table columns={columns} rowKey='id' dataUrl={url.tiposNota.getAll} />
            </>
        }
        />
    </>
  )
}