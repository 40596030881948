import React, { useEffect, useState} from 'react'
import { Menu } from 'antd';
import { AppstoreOutlined, SnippetsOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { RiGroupLine } from "react-icons/ri";
import { GrNotes } from "react-icons/gr";
import { rutas } from '../config.js';
import { isAdmin as fetchAdmin} from '../utils/Blog.js';
import { decrypt } from '../utils/Crypt.js';

export const Navbar = () => {
  
    const [current, setCurrent] = useState('mail');
    const [ items, setItems] = useState([
        {

            label: (<Link to={rutas.notas.viewAll}>Notas</Link>),
            key: 'notas',
            icon: <GrNotes />,
        }
    ]);
    const location = useLocation();
    const itemsAdmin = [
        {

            label: (<Link to={rutas.notas.viewAll}>Notas</Link>),
            key: 'notas',
            icon: <GrNotes />,
        },
        {
            label: (<Link to={rutas.modulos.viewAll}>Módulos</Link>),
            key: 'modulos',
            icon: <AppstoreOutlined />,
        },
        {
            label: (<Link to={rutas.usuarios.viewAll}>Usuarios</Link>),
            key: 'usuarios',
            icon: <RiGroupLine />,
        },
        {
            label: (<Link to={rutas.tipos.viewAll}>Tipos de nota</Link>),
            key: 'tipos',
            icon: <SnippetsOutlined />,
        }
    ]

    const checkRol = async() => {
        const response = await fetchAdmin();
        const isAdmin = parseInt(decrypt(response));
        if(isAdmin){
            setItems(itemsAdmin);
        }
    }


    useEffect(() => {   
        const path = location.pathname.split("/");
        setCurrent(path[1]);
        checkRol();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClick = (e) => {
      console.log('click ', e);
      setCurrent(e.key);
    };
    return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />;
}
