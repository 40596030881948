import React, { useState, useEffect } from 'react'
import { CardBlog } from '../components/CardBlog';
import { getByIdModulo as getByModulo, getNotas   } from '../utils/Blog';
import { Col, Row, Spin } from 'antd';
import { NavigationBlog } from '../components/NavigationBlog';
import { HeaderBlog } from '../components/HeaderBlog';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { decrypt } from '../utils/Crypt';
import '../assets/css/estilosBlog.css';
import { isValidToken, rutas } from '../config';
export const Blog = () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const { modulo } = useParams();
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 800);
  const navigation = useNavigate();


  useEffect(() => {

    if(location.search){
      const preview = new URLSearchParams(location.search).get("previewNota");
      if(preview){
        navigation(rutas.notas.preview);
      }
    }

    getDataBlog();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };




      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {

    if(modulo !== undefined){
      const moduloDecrypted = decrypt(modulo);
      if(moduloDecrypted !== false){
        getByModulo(modulo)
        .then((data) => {
          if(!data){
            throw new Error("No se pudo obtener los datos");
          }
          setData(data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
      })
        
      }
    }


        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getDataBlog = async () => {
    let response = [];

    console.log(isValidToken());

    response = await getNotas();

    if(response.error){
      setData([{
        id: 0,
        titulo: "Error al obtener datos",
        contenido: "Error al obtener datos"
      }]);
    }else{
      setData(response.datos);
    }

    setLoading(false);
  }

  const handleResize = () => {
    setIsWideScreen(window.innerWidth > 800);
  };


  return (
    <>
    <div style={{ height: 300 }}>
    <HeaderBlog />
  </div>
<div className="container" style={{ overflowX: "hidden"}}>
 
  <Row gutter={[16, 16]} style={{ padding: 10 }}>
    <Col xs={24} sm={18}>
      <div id="notas">
        {loading ? (
          <Spin size="large" />
        ) : (
          <>
            {data.length === 0 ? (
              <h1>No hay notas</h1>
            ) : (
              data.map((nota, index) => (
                <div key={index} style={{ marginBottom: 20 }}>
                  <CardBlog data={nota} />
                </div>
              ))
            )}
          </>
        )}
      </div>
    </Col>
    <Col xs={24} sm={6}>
    <div style={{
            position: isWideScreen ? "fixed" : "sticky",
            marginTop: isWideScreen ? 300 : 10,
            top: isWideScreen ? 20 : 0,
            width: isWideScreen ? 220 : "100%",
          }}>
      
        <NavigationBlog />
      </div>
    </Col>
  </Row>
</div>
</>
  )
}
