const urlDominio = window.location.origin;
console.log(urlDominio);
let urlDominioBack = "";
let urlEditor = "";
let entorno  = "qa";
// let entorno2 = "-qa";
let urlP = "";
export const getPayloadToken = () => {

    try{
        const token = localStorage.getItem("tokenPISblog");
        if(token === null || token === undefined || token === "" || token === "null" || token === "undefined"){
            throw new Error("Token no encontrado");
        }
        const payload = token.split(".")[1];
        const decoded = atob(payload);
        return JSON.parse(decoded);
    }catch(e){
        return false
    }
}

export const isValidToken = () => {

    const payload = getPayloadToken();
    
    if(payload === undefined || !payload){
        return false;
    }

    const exp = payload.exp || null;
    const now = Date.now() / 1000 || 0;  

    return !(exp > now) || exp == null ? false : true; 

}



if(urlDominio === "http://localhost:3000"){
    urlDominioBack = "http://localhost:2000/service/index.php/";
    urlEditor = "http://localhost:2000/editor/index.php";

    urlP ="";
}else if(urlDominio === "https://blog.puertointeligenteseguro.com.mx"){
    urlDominioBack = "https://blog.puertointeligenteseguro.com.mx/service/v2/index.php/";
    urlEditor = "https://blog.puertointeligenteseguro.com.mx/service/v2/editor/index.php";
    entorno = "prod";
    // entorno2 = "";
    urlP = "/public/";
}else{
    urlDominioBack = "https://pis-web-variosphp-qa.azurewebsites.net/service/v2/index.php/";
    urlEditor = "https://pis-web-variosphp-qa.azurewebsites.net/service/v2/editor/index.php";
    urlP = "/public/";
}

export const url = {
    notas: {
        getAll: urlDominioBack+"v1/notas",
        getAllAdmin: urlDominioBack+"v2/notas/admin/basic",
        getById: urlDominioBack+"v1/notas/",
        insert: urlDominioBack+"v1/notas",
        update: urlDominioBack+"v1/notas/",
        getBasicById: urlDominioBack+"v1/notas?basic=",
        getForBlogById: urlDominioBack+"v1/notas?view=",
    },

    blog: {
        getAll: urlDominioBack+"v1/blog",
        getById: urlDominioBack+"v1/blog/",
        getByIdModulo: urlDominioBack+"v1/blog?modulo=",
        byUserPIS: urlDominioBack+"v1/blog?userPIS",
        isAuthorized: urlDominioBack+"v2/blog/admin",
        isAdmin: urlDominioBack+"v1/blog?rol",
        getNotas: urlDominioBack+"v2/blog/notas",   
    },
    modulos: {
        getAll: urlDominioBack+"v2/modulos/admin/basic",
        getAllBasic: urlDominioBack+"v1/modulos?basic", 
        getById: urlDominioBack+"v1/modulos/",
        insert: urlDominioBack+"v1/modulos",
        update: urlDominioBack+"v1/modulos/",
        getByUser: urlDominioBack+"v1/modulos?user=",
        sincronizar: urlDominioBack+"v1/modulos?sincronizar",
        getAllBasicForBlog: urlDominioBack+"v1/modulos?blog",
        getAllForBlogByUserPIS: urlDominioBack+"v2/blog/modulos", 
    },
    tiposNota: {
        getAll: urlDominioBack+"v2/tiposNota/admin/basic",
        getById: urlDominioBack+"v1/tiposNota/",
        insert: urlDominioBack+"v2/tiposNota",
        update: urlDominioBack+"v1/tiposNota/",
    },
    usuarios: {
        getAll: urlDominioBack+"v1/usuarios",
        getById: urlDominioBack+"v1/usuarios/",
        insert: urlDominioBack+"v1/usuarios",
        update: urlDominioBack+"v1/usuarios/",
    },
    usuMod: {
        getByUser: urlDominioBack+"v1/usuarios/modulos/",
    },
    api: {
        getPersonas: `https://pis-api-personas-federado-${entorno}.azurewebsites.net/api/personas/v2/curp/`
    },
    assets: {

        muelle: `https://pis-web-variosphp-${"qa"}.azurewebsites.net/muelle.jpg`
    }   
}

export const rutas = {

    notas: {
        viewAll: "/notas",
        edit: "/nota/",
        new: "/nota",
        newCrypt: urlEditor,
        editCrypt: `${urlEditor}?d=`,
        preview: "/nota/preview/",
        blog: "/blog",
        blogHash: "blog/",
    },

    tipos: {
        viewAll: "/tipos",
    },
    modulos: {
        viewAll:  "/modulos",
        edit:  "/modulo/",
        new: "/modulo",
    },
    usuarios: {
        viewAll: "/usuarios",
        edit: "/usuario/",
        new: "/usuario",
    },
    blog: {
        index: "/",
        index2: "/blog",
        nota: "/blog/view/",
        indexAlt: "/blog/",
    },
    basename: urlP,

}