export const FetchData = async(url = null, method = "GET", headers = null, body = null) => {

    try{

        if(url === null){
            throw new Error("URL no puede ser nula");
        }

        const random = Math.random();
        const randomString = random.toString().substr(2);

        //Verificar si url tiene un ? para concatenar el random
        if(url.includes("?")){
            url = url+"&v="+randomString;
        }else{
            url = url+"?v="+randomString;
        }
        const req = await fetch(url, {
            method: method,
            headers: headers,
            body: body
        });

        if(req.status !== 200 || req.ok === false){
            throw new Error("Error en la petición");
        }

        const data = await req.json();

        if(data.error){
            throw new Error(data.message);
        }

        return data;
    }catch(e){
        console.error(e);
        return {
            error: true,
            message: e.message
        };
    }

}