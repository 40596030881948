import React from 'react'
import { CardComponent as Card}  from '../components/Card.js'
import { Table } from '../components/Table.js'
import strings from '../values/strings.json'
import { rutas, url } from '../config.js'
// import { getToken } from '../utils/Storage.js'
// import { useNavigate } from 'react-router-dom'
import { Button, Tooltip, ColorPicker } from 'antd'
import { FileAddOutlined, StopOutlined } from '@ant-design/icons'
import { Navbar } from '../components/Navbar.js'
import { MdOutlineLockPerson } from "react-icons/md";
import { encrypt } from '../utils/Crypt.js'
// import { isAdmin as fetchAdmin } from '../utils/Blog.js'
import { GrGroup } from "react-icons/gr";

export const Notas = () => {

  // const navigate = useNavigate();
  const textTable = strings.tables.columns.title.notas;
  const keyTable = strings.tables.columns.keys;
  const valuesTable = strings.tables.columns.values.notas;
  // const [ isAdmin, setIsAdmin ] = useState(false)
  const columns = [   
    {
      title: textTable.title,
      dataIndex: 'nombre',
      key: keyTable.name,
      type: 'text',
      width: 80,
      fixed: 'left',
    },
    {
      title: textTable.user,
      dataIndex: 'nombreUsu',
      key: keyTable.user,
      type: 'text',
      width: 80,
      fixed: 'left'
    },
    {
      title: textTable.module,
      dataIndex: 'nombreModulo',
      key: keyTable.module,
      type: 'text',
      width: 80,
      fixed: 'left'
    },
    {
      title: textTable.type,
      dataIndex: 'tipoPublico',
      key: keyTable.type,
      type: 'boolean',
      width: 80,
      fixed: 'left',
      render: (_, record) => {
        let icon = null;
        switch(parseInt(record.tipoPublico)){
          case 0: 
          icon = (
            <Tooltip title={valuesTable.type.priv}>
              <MdOutlineLockPerson size={25} style={{fontSize: 20}}/>
            </Tooltip>
          );
    

          break;
          case 1:
          icon = (
            <Tooltip title={valuesTable.type.public}>
               <GrGroup size={25} style={{fontSize: 20}}/>
             </Tooltip>
             ) 

          break;

          case 2:
            icon = (
              <Tooltip title={valuesTable.type.restricted}>
                <StopOutlined size={25} style={{fontSize: 20}} />
              </Tooltip>
            )
          break;

          default:
            icon = null
            break;

        }
        return icon;

      }
    },
    {
      title: textTable.date,
      dataIndex: 'fechaCreacion',
      key: keyTable.date,
      type: 'date',
      width: 80,
      fixed: 'left'
    },
    {
      title: textTable.status,
      dataIndex: 'estatus',
      key: keyTable.status,
      type: 'boolean',
      width: 80,
      fixed: 'left',
      render: (_, record) => {
        return parseInt(record.estatus) === 1 ?
        <Tooltip title={valuesTable.status.active}>
          <ColorPicker defaultValue={"green"} />
        </Tooltip> 
         :
        <Tooltip title={valuesTable.status.inactive}>
          <ColorPicker defaultValue={"red"} />
        </Tooltip>
      }
    },
    {
      title: textTable.actions,
      dataIndex: 'id',
      key: keyTable.actions,
      type: 'action',
      typesAction: [
        {
          type: 'edit',
          text: textTable.edit,
          onclick: (id) => {handleNavigateToEdit(id)}
        },
      ], 
      width: 80,
      fixed: 'left'
    },
 
  ]

  // useEffect(() => {
  //   handleIsAdmin()
  // }, [])

  // const handleIsAdmin = async () => {

  //   if(getToken() && isValidToken()){

  //     const response = await fetchAdmin()

  //     if(response.permisos){
  //       setIsAdmin(true)
  //     }
  //   }
  // }


  const handleNavigateToEdit = (id) => {
    // navigate(`${rutas.notas.edit}${id}`)
    
    const token = localStorage.getItem("tokenPISblog");
    id = encrypt(id);
    const ruta = rutas.notas.editCrypt + id + "&token=" + token;
    //direccionar
    // window.location.href = ruta;
    window.open(ruta);


  }
  const handleNavigateNewNote = () => {
    // navigate(rutas.notas.new)
    const token = localStorage.getItem("tokenPISblog");
    const ruta = rutas.notas.newCrypt + "?token=" + token;
    //direccionar
  
    // window.location.href = ruta;
    window.open(ruta, "_blank");
  }


  return (
    <>
      <Card contenido={
        <>
        <Navbar />
        <Button type="primary" style={{float: "right", margin : 15}} icon={<FileAddOutlined /> } onClick={handleNavigateNewNote} >Crear nota</Button>
        <Table columns={columns} rowKey='id' dataUrl={url.notas.getAllAdmin} />
        </>
      }
      />
    
    </>
  )
}
