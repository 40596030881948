import React from 'react'
import { EyeOutlined } from '@ant-design/icons'
import { CgViewSplit } from "react-icons/cg";
import { MdFullscreen } from "react-icons/md"
import { FaRegWindowRestore } from "react-icons/fa";
import { Tooltip, Typography } from 'antd';
// import { useParams } from 'react-router-dom';
import {rutas } from '../config.js';

const { Title } = Typography;


export const VistaPrevia = () => {

    const handleFullScreen = () => {

        const currentUrl = window.location.href;
        const urlSplited = currentUrl.split("/nota/");
        const newUrl = urlSplited[0] + rutas.notas.preview + urlSplited[1];
        window.open(newUrl, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=600,height=600")

    }


  return (
    <div style={{ background: "#f0f0f0",  padding: 10, borderRadius: 10, marginBottom: 10, border: "1px solid #d9d9d9", boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)", float: "right"}} >
    <div style={{display: "flex"}}>
        <EyeOutlined  style={{fontSize: '30px',} }/>
        <Title level={5} style={{margin: 0, marginLeft: 10}}>Vista previa</Title>
    </div>
    <div style={{
            display: 'flex',
        }}>
            <div style={{width:"50%", background: "#13322B", color: "white", textAlign: "center", borderTopLeftRadius: 10, borderBottomLeftRadius:10, cursor: "pointer"}}>
                <Tooltip title="Vista previa completa">
                    <MdFullscreen size={20} />
                </Tooltip>
            </div>
            <div style={{width:"1px", background: "black", boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)"}}></div> {/* Línea negra delgada */}
            <div  style={{width:"50%", background: "#13322B", color: "white", textAlign: "center",  cursor: "pointer"}}>
                <Tooltip title="Vista previa divida">
                    <CgViewSplit  size={20}/>
                </Tooltip>
            </div>
            <div style={{width:"1px", background: "black", boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)"}}></div> {/* Línea negra delgada */}
            <div onClick={handleFullScreen} style={{width:"50%", background: "#13322B", color: "white", textAlign: "center",  borderTopRightRadius: 10, borderBottomRightRadius:10, cursor: "pointer"}}>
                <Tooltip title="Vista previa nueva ventana">
                <FaRegWindowRestore size={12} />
                </Tooltip>
            </div>
        </div>
</div>
  )
}
