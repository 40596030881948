import { FetchData } from './FetchData'
import { url } from '../config';

const headers = {
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + localStorage.getItem('tokenPISblog')
};

export const getById = async(id) => {
    const response = await FetchData(url.notas.getById + id, 'GET', headers)
    return response.datos;
}

export const getBasicById = async(id) => {
    const response = await FetchData(url.notas.getBasicById + id, 'GET', headers)
    return response.datos;
}

export const add = async(data) => {
    const response = await FetchData(url.notas.insert, 'POST', headers, data)
    return response;
}

export const update = async(id, data) => {
    const response = await FetchData(url.notas.update + id, 'PUT', headers, data)
    return response;
}
