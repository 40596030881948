import React, { useEffect, useState} from 'react'
import { CardComponent as Card}  from '../components/Card.js'
import { ConstructorForm } from '../components/ConstructorForm.js'
import { Table, resetTable } from '../components/Table.js'
import strings from '../values/strings.json'
import { url } from '../config.js'
import { Modal, Button, Spin, Tooltip, ColorPicker } from 'antd'
import { Navbar } from '../components/Navbar.js'
import { FetchData } from '../utils/FetchData.js'
import { dataById as getDataUsu, updateUser as updateUserFetch} from '../utils/Usuarios.js'
import { getAllBasic as modulosGroup, getByUser as modByUsu } from '../utils/Modulos.js'
import { UserAddOutlined } from '@ant-design/icons'

export const Usuarios = () => {

    const textTable = strings.tables.columns.title.usuarios;
    const keyTable = strings.tables.columns.keys;
    const valuesTable = strings.tables.columns.values.usuarios;
    const [openModal, setOpenModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState({})
    const [showForm, setShowForm] = useState(false)


    useEffect(() => {
        //eliminar storage de basicModulosPISblog
        localStorage.removeItem('basicModulosPISblog');
    },[])

    const updateUser = async(data, id) => { 
        
        setIsLoading(true);
        setOpenModal(false);
        const isOk = await updateUserFetch(data, id);
        if(isOk.error){
            console.log(isOk.error)
            //To do: Mostrar alerta de error
            //abrir modal nuevamente
            setIsLoading(false);
            setOpenModal(true);
        }
        setIsLoading(false);
        handleCloseModal();
        resetTable();
    }

    const addUser = async(data) => { 
        setIsLoading(true);
        setOpenModal(false);
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('tokenPISblog') 
        }

        const response = await FetchData(url.usuarios.insert, 'POST', headers,JSON.stringify(data),) 
        
        if(response.error){
            console.log(response.error)
            //To do: Mostrar alerta de error
            //abrir modal nuevamente
            setOpenModal(true);
        }
        setIsLoading(false);
        handleCloseModal();
        resetTable();
    }

    const formJson = {
            title: "Test",
            layout: "horizontal",
            id: "formUsuarios",
            initialValues: {
                nombre: "",
            },
            style:{
                margin: 10
            },
        elements:[
            {
                type: "checkboxGroup",
                row: 1,
                col: 0,
                propsForm: {
                    label: "Modulos",
                    name: "modulos",
                    // rules: [
                    //     {
                    //         required: true,
                    //         message: "Por favor rellenar campo",
                    //     },
                    // ],
                },
                propsElement: {
                    options: [],
                    style: {
                        background: "#f0f0f0",
                        padding: 10,
                        borderRadius: 5,
                        display: "flex",
                        flexDirection: "column",
                        border: "1px solid #d9d9d9",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)", 
                        maxHeight: 200,
                        overflowY: "auto",
                        /* Estilos adicionales para forzar una sola columna */
                        flexWrap: "nowrap", // Evita que los elementos se envuelvan a una nueva línea
                        alignItems: "flex-start", // Alinea los elementos al principio de la columna
                    }
                },
            },

            {
                type: "text",
                row: 2,
                col: 0,
                propsForm: {
                    label: "Nombre",
                    name: "correo",
                    // rules: [
                    //     {
                    //         required: true,
                    //         message: "Por favor rellenar campo",
                    //     },
                    // ]
                },
                propsElement: {
                    placeholder: ""
                  
                    // disabled: true,
                },
            },
            // {
            //     type: "select",
            //     propsForm: {
            //         label: "Rol",
            //         row: 3,
            //         col: 0,
            //         name: "rol",
            //         rules: [
            //             {
            //                 required: true,
            //                 message: "Por favor rellenar campo",
            //             },
            //         ]
            //     },
            //     propsElement: {
            //         placeholder: "",
            //         options: [
            //             { label: 'Administrador', value: 1 },
            //             { label: 'Editor', value: 2 },
            //         ]  
                  
            //         // disabled: true,
            //     },
            // },
            {
                type: "switch",
                row: 4,
                col: 0,
                propsForm: {
                    label: "Estatus",
 
                    name: "estatus",
                    // rules: [
                    //     {
                    //         required: true,
                    //         message: "Por favor rellenar campo",
                    //     },
                    // ],
                },
                propsElement: {
                    style: {
                        width: "50%",
                    },
                    checkedChildren: "Activo",
                    unCheckedChildren: "Inactivo",
                },
            }
        ],
        buttons: [
            {
                type: "submit",
                text: "Guardar",
                propsElement: {
                    className: "btnSubmit",
                    type: "primary",
                    style: {
                      
                    },
    
                },
            },
    
        ]
    }

    const handleOpenModal = async(id) => {

        setOpenModal(true)
        // setId(id)
        const plantillaForm = {...formJson}
        const dataModulos = await modulosGroup();
        const modulos = dataModulos.map((item) => {
            return {label: item.nombre, value: parseInt(item.id)}
        })
        plantillaForm.elements[0].propsElement.options = modulos;
        if(id === 0){
            plantillaForm.initialValues = {
                nombre: "",
                correo: "",
                estatus: true,
                modulos: []
            }
            plantillaForm.onFinish = addUser
            // plantillaForm.buttons.elements[0].text = "Guardar"
        }else{
            const dataModUsu = await modByUsu(id);
            const modUsu = dataModUsu.map((item) => parseInt(item.idModulo))
            const dataUsu = await getDataUsu(id);
            dataUsu.estatus = parseInt(dataUsu.estatus) === 1 ? true : false;
            const initialValues = {...dataUsu, modulos: modUsu}
            plantillaForm.initialValues = initialValues;
            plantillaForm.onFinish = (data) => updateUser(data, id)
            // plantillaForm.buttons.elements[0].text = "Actualizar"
        }
        
        setForm(plantillaForm);
        setShowForm(true)
    }

    const handleCloseModal = () => {
        // setId(0)
        setOpenModal(false)
        setShowForm(false)
    }

    const columns = [   
        {
          title: textTable.title,
          dataIndex: 'correo',
          key: keyTable.name,
          type: 'text',
          width: 80,
          fixed: 'left',
        },
        {
            title: textTable.status,
            dataIndex: 'estatus',
            key: keyTable.status,
            type: 'boolean',
            width: 80,
            fixed: 'left',
            render: (_, record) => {
                return parseInt(record.estatus) === 1 ?
                <Tooltip title={valuesTable.status.active}>
                  <ColorPicker defaultValue={"green"} />
                </Tooltip> 
                 :
                <Tooltip title={valuesTable.status.inactive}>
                  <ColorPicker defaultValue={"red"} />
                </Tooltip>
              }
          },
        {
          title: textTable.actions,
          dataIndex: 'id',
          key: keyTable.actions,
          type: 'action',
          typesAction: [
            {
              type: 'edit',
              text: textTable.edit,
              onclick: (id) => {handleOpenModal(id) }
            },
          ], 
          width: 80,
          fixed: 'left'
        },
     
      ]

      

    return (
        <>
            <Spin spinning={isLoading} fullscreen/>
          <Card contenido={
            <>
               <Modal
                    destroyOnClose
                    title="Actualizar"
                    open={openModal}
                    onCancel={handleCloseModal}
                    footer={null}
                    >
                        <>
                        <hr/>
                        {showForm ? <ConstructorForm jsonForm={form}  /> : <Spin size="large" />} 
                        </>
                </Modal>
                <Navbar />
                <Button type="primary" icon={<UserAddOutlined  style={{fontSize: 18}}/>} style={{float:"right", margin: 15}} onClick={() => handleOpenModal(0)}>Agregar</Button> 
            <Table columns={columns} rowKey='id' dataUrl={url.usuarios.getAll} />
            </>
          }
          /> 
        </>
      )
}
