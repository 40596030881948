import React from 'react'
import { LinkOutlined } from '@ant-design/icons';
import { Tooltip, message } from 'antd';
import { CopyToClipboard  } from 'react-copy-to-clipboard';
import { useParams } from 'react-router-dom';
import { rutas } from '../config';
export const CopyLink = ({color = "white", hashId = null}) => {

  const [messageApi, contextHolder] = message.useMessage();
  const { hashNota } = useParams();

  const basename = rutas.basename;
  let url = window.location.origin;
//quitar ultimo slash
  if(url.slice(-1) === "/"){
    url = url.slice(0, -1);
  }

  //Quitar ?token=
  if(url.includes("?token=")){
    url = url.split("?token=")[0];
  }

//TEST
  const textToCopy = `${url}${basename}?preview=${hashId || hashNota}`;

  const handleCopy = () => {
    messageApi.open({
      type: 'success',
      content: 'Enlace copiado al portapapeles!',
      className: 'custom-class',
      style: {
        marginTop: '40vh',
      },
    });
  }

  return (
    <div>
       {contextHolder}
        <Tooltip title="Copiar link">
          <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
            <LinkOutlined style={{color: color, fontSize: 18, cursor: "pointer"}} />
          </CopyToClipboard>
        </Tooltip>
    </div>
  )
}
