
import { Route,  BrowserRouter as Router, Routes } from 'react-router-dom';
import { Notas } from './views/Notas';
import { Formulario } from './views/Formulario';
import { Modulos } from './views/Modulos';
import { Usuarios } from './views/Usuarios';
import { Preview } from './views/Preview';
import { TiposNotas } from './views/TiposNotas';
// import { Home } from './views/Home.js';
import {Blog } from './views/Blog.js';
import { ViewNota } from './views/ViewNota.js';
import { rutas , isValidToken} from './config.js';
import {isAuthorized as fetchAdmin} from './utils/Blog.js';  
import { getToken } from './utils/Storage.js';
import React,{ useEffect, useState } from 'react';
import { Spin } from 'antd';
import { decrypt } from './utils/Crypt.js';


//To prod Ready 2
// import { _ConstructorForm } from './components/_ConstructorForm.js';asd

const App = () => {
  
  const [isAdmin, setIsAdmin] = useState(false)
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let token = params.get('token') || null;
    const isAppPISnote = params.get('app') || false;
    if(token){

      if(isAppPISnote){
        const tokenFixed = token.replace(/ /g, '+');
        token = tokenFixed;
      }


      setTimeout(() => {
        localStorage.setItem('tokenPISblog', token);
        setTimeout(() => {
          handleIsAdmin();
        }, 1000);
      }, 1000);
    }else{
      setIsLoading(false);
    }
  }, []);


  const handleIsAdmin = async() => {
        if(getToken() && isValidToken()){
            const response = await fetchAdmin()
            const isAuthorized = parseInt(decrypt(response));
            if(isAuthorized){
                setIsAdmin(true);
            }
        }
        setIsLoading(false);
    }

    return (
      <>
      {
        isLoading ?
        <Spin fullscreen/>
        :
        <Router basename={rutas.basename}>
          <Routes>
            {isAdmin ? (
              <React.Fragment>
                <Route path={rutas.notas.viewAll} element={<Notas />} />
                <Route exact path={rutas.notas.edit+":idNota"} element={<Formulario />} />
                <Route exact path={rutas.notas.new} element={<Formulario />} />
                <Route path={rutas.notas.preview+":idNota"} element={<Preview />} />
                <Route path={rutas.modulos.viewAll} element={<Modulos />} />
                <Route path={rutas.usuarios.viewAll} element={<Usuarios />} />
                <Route exact path={rutas.blog.index} element={<Blog />} />
                <Route exact path={rutas.blog.index2} element={<Blog />} />
                <Route path={rutas.blog.indexAlt+":modulo"} element={<Blog />} />            
                <Route path={rutas.tipos.viewAll} element={<TiposNotas />} />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Route exact path={rutas.blog.index} element={<Blog />} />
                <Route exact path={rutas.blog.index2} element={<Blog />} />
                <Route path={rutas.blog.indexAlt+":modulo"} element={<Blog />} />
              </React.Fragment>
            )} 

            <Route path="*" element={<Blog />} /> 
            <Route path={rutas.notas.preview} element={<Preview />} />
            <Route path={rutas.blog.nota+":hashNota"} element={<ViewNota />} />
          </Routes>
        </Router>
        }
      </>
    );
    

}

export default App;
