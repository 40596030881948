import React,{useEffect, useState} from 'react'
import { Card, Tag, Typography } from 'antd';
import { CopyLink } from './CopyLink';
import { url } from '../config';

const { Title, Text } = Typography;

export const EncabezadoNota = ({data}) => {

  const [isMobile, setIsMobile] = useState(false);

  //useEffect que verifique los cambios de tamaño de la pantalla
  useEffect(() => {
    const handleResize = () => {

      //cambiar tamaños para computadora, laptop y celular
      if(window.innerWidth <= 500){
        setIsMobile(true)
      }else{
        setIsMobile(true)
      }
    }
    //evento que escucha el cambio de tamaño de la pantalla
    window.addEventListener('resize', handleResize)
    //limpieza de evento
    return () => {
      window.removeEventListener('resize', handleResize)
    }
    }, [])
  const parseFecha = (fecha = null) => {
    if(!fecha) fecha = new Date();
    
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const fechaParseada = new Date(fecha).toLocaleDateString('es-ES', options);
    return fechaParseada;
}

  return (
    <>
    { !isMobile ?
        <Card
        style={{
        width: "100%",
          height: 204,
          backgroundImage: `url(${url.assets.muelle})`,
          backgroundSize: 'cover',
          //filtro a imagen
          // filter: "brightness(90%)",
          //filtro color de imagen segun sea data.background
          mixBlendMode: "multiply",
          backgroundBlendMode: "multiply",
          //filtro de color
          backgroundColor: data.background,
          //filtro de color  
        }}
      >
        <div style={{ marginTop: 20, marginLeft: 20 }} >
          <div>
            <Tag style={{
              marginBottom: 2,
            }} color={data.background}>{data.nombreModulo || ""}</Tag>
            <Tag color="rgb(0, 159, 151)">{data.nombreTipoNota || ""}</Tag>
          </div>
          <div>
            <Title level={3} style={{ 
              color: "white",
            }}>{data.titulo || ""} </Title>
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <Text style={{color: "white"}}>{parseFecha(data.fecha) || 
                        //fecha actual
                        parseFecha()
                        }</Text>
            </div>
            <div style={{color: "white", marginInline:5}}>
              |
            </div>
            <div>
                <CopyLink />
            </div>
          </div>
        </div>
      </Card>
      :
      <div
      style={{
        padding:10,
        borderRadius: 10, 
        backgroundImage: `url(${url.assets.muelle})`,
        backgroundSize: 'cover',
        //filtro a imagen
        // filter: "brightness(90%)",
        //filtro color de imagen segun sea data.background
        mixBlendMode: "multiply",
        backgroundBlendMode: "multiply",
        //filtro de color
        backgroundColor: data.background,
      }}

      >
      <div>
        <Tag style={{
          marginBottom: 2,
        }} color={data.background}>{data.nombreModulo || ""}</Tag>
        <Tag color="rgb(0, 159, 151)">{data.nombreTipoNota || ""}</Tag>
      </div>
      <div>
        <Title level={3} style={{ 
          color: "white",
        }}>{data.titulo || ""} </Title>
      </div>
      <div style={{ display: "flex" }}>
        <div>
          <Text style={{color: "white"}}>{parseFecha(data.fecha) || 
                    //fecha actual
                    parseFecha()
                    }</Text>
        </div>
        <div style={{color: "white", marginInline:5}}>
          |
        </div>
        <div>
            <CopyLink />
        </div>
      </div>
    </div>

    }
  </>

  )
}
