import React from 'react'
import { Space, Tooltip } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, HomeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { rutas } from '../config';

export const NavigationButtons = () => {

    const navigate = useNavigate();

    const goHome = () => {
        navigate(rutas.blog.index);
    }

  return (
    <div>
        <Space>
        <ArrowLeftOutlined style={{
            
            color: "rgba(0, 0, 0, 0.25)",
            cursor: "pointer",
            fontSize: 25,
        }} />
        <Tooltip title="Volver al Blog">
            <HomeOutlined
            onClick={goHome}
            style={{
                marginInline: 10,
                color: "#1677ff",
                cursor: "pointer",
                fontSize: 25
            }}
            />
        </Tooltip>
        <ArrowRightOutlined 
        style={{
            
            color: "rgba(0, 0, 0, 0.25)",
            cursor: "pointer",
            fontSize: 25,
        }}
        />
        </Space>

    </div>
  )
}
