import { url } from "../config"
import { FetchData } from "./FetchData"

const headers = {
    'Content-Type': 'application/json', 
    'Authorization': 'Bearer ' + localStorage.getItem('tokenPISblog')
}

export const getById = async(id) => {

    const data = await FetchData(url.tiposNota.getById + id, 'GET', headers)
    return data.datos;

}

export const add = async(data) => { 

    const res = await FetchData(url.tiposNota.insert, 'POST', headers, data)
    return res;

}

export const update = async(data, id) => {

    const res = await FetchData(url.tiposNota.update + id, 'PUT', headers, data);
    return res;

} //Test
