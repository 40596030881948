import React, { useState} from 'react'
import { CardComponent as Card}  from '../components/Card.js'
import { ConstructorForm } from '../components/ConstructorForm.js'
import { Table, resetTable } from '../components/Table.js'
import strings from '../values/strings.json'
import { url } from '../config.js'
import { Modal, Spin, Button, Tooltip, ColorPicker} from 'antd'
import { Navbar } from '../components/Navbar.js'
import { updateModulo as updMod, sync as sincronizarModulos } from '../utils/Modulos.js'
import { MdOutlineSyncAlt } from 'react-icons/md'


export const Modulos = () => {

    const textTable = strings.tables.columns.title.modulos;
    const keyTable = strings.tables.columns.keys;
    const valuesTable = strings.tables.columns.values.modulos;

    const [openModal, setOpenModal] = useState(false)
    const [form, setForm] = useState({})
    const [showForm, setShowForm] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const updateData = async(data) => { 

        const obj = {
            estatus: data.estatus ? 1 : 0,
            background: parseColorHex(data.background),
        }
        setIsLoading(true)
        setOpenModal(false)
        const response = await updMod(JSON.stringify(obj), data.id);
        if(response.error){
            setOpenModal(true)
        }
        setIsLoading(false)
        handleCloseModal()
        resetTable()

    }

    const handleSyncModulos = async() => {
        setIsLoading(true)
        const response = await sincronizarModulos();
        setIsLoading(false)
        if(response.error){
            //TO DO: Mostrar mensaje de error

        }
        setIsLoading(false)
        resetTable()
    }



    const formJson = {
            title: "Test",
            layout: "horizontal",
            id: "formNota",
            initialValues: {
                nombre: "",
            },
            style:{
                margin: 10
            },
        elements:[
            {
                type: "hidden",
                row: 1,
                col: 0,
                propsForm: {
                    label: "",
                    style: {
                        display: "none",    
                    },

                    name: "id",
                    // rules: [
                    //     {
                    //         required: true,
                    //         message: "Por favor rellenar campo",
                    //     },
                    // ],
                },
                propsElement: {
                    placeholder: "",
                    disabled: true,
                },
            },
            {
                type: "text",
                row: 2,
                col: 0,
                propsForm: {
                    label: "Nombre",
                    name: "nombre",
                    rules: [
                        {
                            required: true,
                            message: "Por favor rellenar campo",
                        },
                    ],
                },
                propsElement: {
                    placeholder: "",
                    disabled: true,
                },
            },
            {
                type: "colorPicker",
                row: 3,
                col: 0,
                propsForm: {
                    label: "Color",
                    name: "background",
                },
                propsElement: {
                    defaultValue: "#731D1D",
                },   
            },
            {
                type: "switch",
                row: 4,
                col: 0,
                propsForm: {
                    label: "Estatus",

                    name: "estatus",
                    rules: [
                        {
                            required: true,
                            message: "Por favor rellenar campo",
                        },
                    ],
                },
                propsElement: {
                    style: {
                        width: "50%",
                    },
                    checkedChildren: "Activo",
                    unCheckedChildren: "Inactivo",
                },
            },
          
        ],
        buttons:[
            {
                type: "submit",
                text: "Actualizar",
                propsElement: {
                    className: "btnSubmit",
                    type: "primary",
                    style: {
                      
                    },
    
                },
            },
        ]
    }

    const handleOpenModal = (record) => {
        record.estatus = parseInt(record.estatus) === 1 ? true : false;
        const plantillaForm = {...formJson}
        plantillaForm.initialValues = record
        plantillaForm.onFinish = updateData
        setForm(plantillaForm)
        setShowForm(true)
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
        setShowForm(false)
    
    }

    const parseColorHex = (color) => {

        //Si valor color incluye # return, en caso de que no, se agrega
        if(color.includes("#")) return color;
        return `#${color}`;


    }


    const columns = [   
        {
          title: textTable.title,
          dataIndex: 'nombre',
          key: keyTable.name,
          type: 'text',
          width: 80,
          fixed: 'left',
        },
        {
            title: textTable.idApp,
            dataIndex: 'idApp',
            key: keyTable.idApp,
            type: 'int',
            width: 80,
            fixed: 'left'
        },
        {

            title: textTable.background,
            dataIndex: 'background',
            key: keyTable.id,
            type: 'color',
            width: 80,
            fixed: 'left',
            render: (_, record) => {
                return <ColorPicker defaultValue={record.background} />
            }
        },
        {
            title: textTable.status,
            dataIndex: 'estatus',
            key: keyTable.status,
            type: 'boolean',
            width: 80,
            fixed: 'left',
            render: (_, record) => {
                return parseInt(record.estatus) === 1 ?
                <Tooltip title={valuesTable.status.active}>
                  <ColorPicker defaultValue={"green"} />
                </Tooltip> 
                 :
                <Tooltip title={valuesTable.status.inactive}>
                  <ColorPicker defaultValue={"red"} />
                </Tooltip>
              }
          },
        {
          title: textTable.actions,
          dataIndex: 'id',
          key: keyTable.actions,
          type: 'action',
          typesAction: [
            {
              type: 'edit',
              text: textTable.edit,
              allData: true,
              onclick: (data) => {handleOpenModal(data) }
            },
          ], 
          width: 80,
          fixed: 'left'
        },
     
      ]

    return (
        <>
            <Card contenido={
                <>
                {isLoading && <Spin size='large' fullscreen />}

                <Modal
                        destroyOnClose
                        title="Actualizar"
                        open={openModal}
                        onCancel={handleCloseModal}
                        footer={null}
                        >
                            <hr/>
                            {showForm ? <ConstructorForm jsonForm={form} /> : <Spin size='large' />}

                    </Modal>
                    <Navbar />
                    <Button type="primary" style={{float: "right", margin : 15}} icon={<MdOutlineSyncAlt />} onClick={handleSyncModulos} sty>Sincronizar</Button>
                <Table columns={columns} rowKey='id' dataUrl={url.modulos.getAll} />
                </>
            }
            />
        </>
      )
}
