import React, { useState, useEffect } from 'react'
import { getById as getNota } from '../utils/Blog'
import { useParams } from 'react-router-dom';
import { EncabezadoNota as Encabezado } from './EncabezadoNota';
import { NavigationBlog } from './NavigationBlog';
import { NavigationButtons } from './NavigationButtons';
import { Row, Col, Spin } from 'antd';
import { decrypt } from '../utils/Crypt';



export const NotaBlog = ({isPreview = false}) => {

  const [nota, setNota] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { hashNota } = useParams();
  const [isError, setIsError] = useState(false);
  const [showNavigation, setShowNavigation] = useState(false);

  const getStoragePreview = () => JSON.parse(localStorage.getItem('previewPISblog')) || {};

  useEffect(() => {

    const isApp = new URLSearchParams(window.location.search).get("app") || false;

      setShowNavigation(!isApp);
    

    setIsLoading(true);
    if(isPreview){
      window.addEventListener('message', (event) => {
        let origin2 =event.origin;
        if(event.origin === 'http://localhost:2000'){
          origin2 = 'http://localhost:2000';
        }

        if (event.origin === origin2 ) {
            setNota(event.data);
            localStorage.setItem('previewPISblog', JSON.stringify(event.data));
        }
      });
      setNota(getStoragePreview());

      window.addEventListener('storage', handleChangeStorage);
      setIsLoading(false);
      return;
    }
    const idCrypted = validarHash();
    if (idCrypted) {
      getNota(idCrypted)
        .then((data) => {
          if(data){
            setNota(data);
          }else{
            setIsError(true)
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
    }
    
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChangeStorage = (event) => {
    if (event.storageArea === localStorage) {
      if(event.key === 'previewPISblog'){
        const data = JSON.parse(event.newValue);
        if(data){
          setNota(data);
        }
      }
     
    }
  }

  //detectar cambios de localStorage

  // Validar si hash es un id valido
  const validarHash = () => {
    const idEncrypt = hashNota;
    let isValid = false;
    if(idEncrypt.length > 0){
      const id = decrypt(idEncrypt);
      if(id !== null && id !== undefined && id !== "" && !isNaN(id) && parseInt(id) !== 0){
        isValid = idEncrypt;
      }
    }
    return isValid;
  }



  return (
    <>
      <div>
         <Spin size='small' spinning={isLoading}>
          <Row>
            {isError ? <h1>Hubo un error al obtener la nota</h1> 
              :
              <>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Encabezado data={nota} />
                  </Col>
                  <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                    <div  style={{ marginTop: 20 }}>
                      {
                        //mostrar nota.contenido como html
                        <div 
                        dangerouslySetInnerHTML={{ __html: nota.contenido }} 
                        style={{ wordWrap: 'break-word' }} // Agrega word-wrap para un mejor manejo del texto largo
                      />

                      }
                      <div style={{ marginTop: 20, marginInline: 20, width: "90%" }}>
                        <hr style={{ width: "100%" }} />
                      </div>
                      <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: 20,
                      }}>
                        {
                          showNavigation &&
                          <NavigationButtons />
                        }
                        </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                    <div style={{ marginTop: 10, marginLeft: 30 }}>
                      { showNavigation &&
                        <NavigationBlog />
                      }
                    </div>
                  </Col>
                </>
              }
          </Row>
        </Spin>
      </div>
    </>
  )
}
