import CryptoJS from 'crypto-js';

const _key = process.env.REACT_APP_KEY_CRYPTO;
const _iv = process.env.REACT_APP_IV_CRYPTO;

const keyNoHex = () => CryptoJS.enc.Hex.parse(_key);
const ivNoHex = () => CryptoJS.enc.Hex.parse(_iv);

export const encrypt = (data) => { 

    const key = keyNoHex();
    const iv = ivNoHex();

    const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv });
    return parcheEncrypt(encrypted.toString()) || false;

}

export const decrypt = (data) => {

    data = parcheDecrypt(data);
    const key = keyNoHex();
    const iv = ivNoHex();

    return CryptoJS.AES.decrypt(data, key, { iv: iv }).toString(CryptoJS.enc.Utf8) || false;

}

const parcheEncrypt = (data) => {

    //si data contiene "/" se reemplaza por -47-
    if(data.includes("/")){
        data = data.replace(/\//g, "-47-");
    }
    //si data contiene "+" se reemplaza por -43-
    if(data.includes("+")){
        data = data.replace(/\+/g, "-43-");
    }

    return data;

}

const parcheDecrypt = (data) => {
    //si data contiene "-47-" se reemplaza por /
    if(data.includes("-47-")){
        data = data.replace(/-47-/g, "/");
    }
    //si data contiene "-43-" se reemplaza por +
    if(data.includes("-43-")){
        data = data.replace(/-43-/g, "+");
    }

    return data;
}