import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import { ConfigProvider } from 'antd';

//Test Deploy 7


const root = ReactDOM.createRoot(document.getElementById('root'));


//tardar un segundo y mandar "" a localStorage
// localStorage.setItem('tokenPISblog', "");
// localStorage.setItem('tokenPISblog',token);
document.body.style = 'background: #f0f0f0;';


const colorDefault = {
  primaryColor: "#13322B"
}

root.render(
  // <React.StrictMode>
       <ConfigProvider
    theme={{
      components: {
        Button: {
          colorPrimary: colorDefault.primaryColor,
          algorithm: true, // Enable algorithm
        },
        FloatButton:{
          colorPrimary: colorDefault.primaryColor,
          algorithm: true, // Enable algorithm
        },


      },
    }}
    >
    <App /> 
    </ConfigProvider>
  // </React.StrictMode>
);
