import { FetchData } from './FetchData'
import { url } from '../config'

const getHeaders = () => {  

    return {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('tokenPISblog')
    };

}
export const useModulos = () => {

    // const getAll = async() => {
    //     const headers = {
    //         'Content-Type': 'application/json',
    //         'Authorization': 'Bearer ' + localStorage.getItem('tokenPISblog')
    //     }
    //     const response = await FetchData(url.modulos.getAll, 'GET', headers)
    //     return response
    // }
}

export const getAllModulos = async() => {

    const storage = localStorage.getItem('modulosPISblog');

    if(storage !== null && storage !== "undefined" && JSON.parse(storage).length){
        console.log("Datos encontrados en el almacenamiento local");
        return JSON.parse(storage);
    } else {
        try {
            const response = await FetchData(url.modulos.getAll, 'GET', getHeaders())
            const data = response.datos;
            localStorage.setItem('modulosPISblog', JSON.stringify(data));  
            console.log("Datos recuperados del servidor y almacenados en el almacenamiento local");
            return data;
        } catch (error) {
            console.error("Error al recuperar datos del servidor:", error);
            throw error;
        }
    }

}

export const getById = async(id) => {

    const response = await FetchData(url.modulos.getById + id, 'GET', getHeaders())
    return response.datos;

}


export const getAllBasic = async() => { 
    const storage = localStorage.getItem('basicModulosPISblog');

    if(storage !== null && storage !== "undefined" && JSON.parse(storage).length){
        console.log("Datos encontrados en el almacenamiento local");
        return JSON.parse(storage);
    } else {
        try {
            const response = await FetchData(url.modulos.getAllBasic, 'GET', getHeaders());
            const data = response.datos;
            localStorage.setItem('basicModulosPISblog', JSON.stringify(data));  
            console.log("Datos recuperados del servidor y almacenados en el almacenamiento local");
            return data;
        } catch (error) {
            console.error("Error al recuperar datos del servidor:", error);
            throw error;
        }
    }
};

export const getAllBasicForBlog = async() => {
    const response = await FetchData(url.modulos.getAllBasicForBlog, 'GET', getHeaders());
    return response.datos;

}

export const getAllBasicForBlogByUserPIS = async() => {
    const response = await fetch(url.modulos.getAllForBlogByUserPIS,{
        method: 'GET',
        headers: getHeaders()
    });

    if(response.ok){
        const data = await response.json();
        return data;
    }else{
        return {
            error: true,
            mensaje: "Error al obtener datos"
        }
    }
}


export const getByUser = async(id) => {

    const response = await FetchData(url.usuMod.getByUser + id, 'GET', getHeaders());
    return response.datos;

}

export const sync = async() => {

    const response = await FetchData(url.modulos.sincronizar, 'PUT', getHeaders());
    return response;

}

export const updateModulo = async(data, id) => {

    const response = await FetchData(url.modulos.update + id, 'PUT', getHeaders(), data);
    return response;

}


